<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :preloader="preloader"
        />
      </v-col>

      <v-col cols="4">
        <Subnavigation root="/csm" :pageTitle="pageTitle"></Subnavigation>
      </v-col>
    </v-row>

    <CreateCompany @created="itemCreated" :buttonFloating="true" />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import CreateCompany from "@/components/companies/CreateCompany.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
export default {
  name: "index",

  components: {
    PageHeader,
    DataTable,
    CreateCompany,
    Subnavigation,
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  data() {
    return {
      page: 1,
      pageTitle: "Companies",
      pageDescription: "Zentraler Zugriff auf alle Firmen",
      edit: true,
      trash: true,
      copy: false,
      search: "",
      headers: [],
      items: [],
      itemActions: {
        edit: true,
        preview: true,
        trash: true,
        copy: true,
        stats: true,
        leads: true,
      },
      preloader: false,
      createItem: false,
      formTemplate: [
        {
          title: "Einen Firma anlegen",
          route: "companies",

          template: [
            {
              label: "Branche",
              model: "businessField",
              value: "",
              element: "select",
              type: "",
              items: [
                { name: "Fitness", value: "fitness" },
                { name: "Golf", value: "golf" },
                { name: "Physio", value: "physio" },
                { name: "EMS", value: "ems" },
                { name: "Sonstige", value: "other" },
              ],
              required: true,
            },
            {
              label: "Name",
              model: "name",
              value: "",
              element: "input",
              type: "text",
              required: true,
            },
            {
              label: "Firmenname",
              model: "legalName",
              value: "",
              element: "input",
              type: "text",
              required: false,
            },
            {
              label: "Rechtsform",
              model: "legalForm",
              value: "",
              element: "select",
              type: "",
              items: [
                { name: "GmbH", value: "gmbh" },
                { name: "GbR", value: "gbr" },
              ],
              required: true,
            },
            {
              label: "Straße",
              model: "street",
              value: "",
              element: "input",
              type: "text",
              required: true,
            },
            {
              label: "Hausnummer",
              model: "streetNumber",
              value: "",
              element: "input",
              type: "text",
              required: true,
            },
            {
              label: "Postleitzahl",
              model: "zipCode",
              value: "",
              element: "input",
              type: "text",
              required: true,
            },
            {
              label: "Stadt",
              model: "town",
              value: "",
              element: "input",
              type: "text",
              required: true,
            },
            {
              label: "Land",
              model: "country",
              value: "",
              element: "select",
              type: "",
              items: [
                { name: "Deutschland", value: "DE" },
                { name: "Österreich", value: "AT" },
                { name: "Schweiz", value: "SZ" },
                { name: "Niederlande", value: "NL" },
                { name: "Belgien", value: "BE" },
              ],
              required: true,
            },
            {
              label: "E-Mail",
              model: "email",
              value: "",
              element: "input",
              type: "email",
              required: true,
            },
            {
              label: "Telefon",
              model: "phone",
              value: "",
              element: "input",
              type: "tel",
              required: true,
            },
            {
              label: "Fax",
              model: "fax",
              value: "",
              element: "input",
              type: "tel",
              required: false,
            },
            {
              label: "Webadresse",
              model: "url",
              value: "",
              element: "input",
              type: "url",
              required: false,
            },
          ],
        },
      ],
    };
  },

  methods: {
    async getData() {
      this.preloader = true;

      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest("companies"); // await data from mixin
      this.preloader = false;
      // if complete (response, data or false)

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Status", value: "status" },
          { text: "Name", value: "name" },
          { text: "Branche", value: "businessCategory" },
          { text: "Postleitzahl", value: "zipCode" },
          { text: "Ort", value: "town" },
          { text: "Land", value: "country" },
          { text: "", value: "actions", sortable: false },
        ];

        this.items = response.data.data;
        (this.edit = true),
          (this.trash = true),
          (this.copy = false),
          (this.search = "");
      }
    },

    addItem() {
      this.createItem = true;
    },

    itemCreated() {
      this.getData(); // reload
      this.createItem = false;
    },

    /* Emitted Actions:
    Hier werden die aus der <DataTable></DataTable> Komponente 'emittete' Werte verarbeitet. 
    Die Komponente übermittelt die Button-Klicks ... editItem etc.*/

    editItem(id) {
      this.$router.push({
        name: "csm.company-manager.:id",
        params: { id: id },
      });
    },

    deleteItem(id) {
      alert("In progress: " + id);
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "csm.company-manager.:id",
          params: { id: value.itemId },
        });
      } else if (value.action === "closeModal") {
        this.createItem = false;
      } else if (value.action === "createdItem") {
        this.getData(); // reload
        this.createItem = false;
      }
    },

    updateURLParameter(page) {
      if (parseInt(this.$route.query.page) !== page) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, { page: page }),
        });
      }
    },
  },

  mixins: [apiRequest],

  middleware: "auth",
};
</script>
